<template>
  <v-form ref="form" lazy-validation>
    <v-dialog
      v-model="showform"
      max-width="1200px"
      persistent
      transition="dialog-top-transition"
      @keydown.esc="close"
    >
      <v-card>
        <v-card-title>
          <span>
            {{
              (editedItem.id > 0 ? "" : "New Test") +
              (editedItem.id > 0 ? "Test # " + editedItem.code : "")
            }}
          </span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-subtitle>Status : {{ item.status }}</v-card-subtitle>
        <v-card-text>
          <v-tabs v-model="tab0" v-if="editedItem.id > 0" @change="chart_show">
            <v-tabs-slider></v-tabs-slider>
            <v-tab key="1" href="#1"> Test Data </v-tab>
            <v-tab key="2" href="#2" v-if="editedItem.statut_id >= 4">
              Analyze Results
            </v-tab>
            <v-tab key="3" href="#3"> Events Log </v-tab>
            <v-tab key="4" href="#4">
              Attachments <v-icon> mdi-paperclip </v-icon>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab0">
            <v-tab-item :value="'1'">
              <v-container>
                <v-row>
                  <v-col cols="12" sm="2" md="2">
                    <v-autocomplete
                      :items="wellstubulars"
                      v-model="editedItem.well_tubular_id"
                      item-value="id"
                      item-text="annulus"
                      label="Annulus"
                      :rules="[(v) => !!v || 'Annulus required']"
                      outlined
                      @input="changes++"
                      :readonly="test_readonly"
                      @change="tubular_change"
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="2" md="2">
                    <v-select
                      :items="fluids"
                      v-model="editedItem.fluid_id"
                      item-value="id"
                      item-text="label"
                      label="Fluid"
                      :rules="[
                        (v) => !!v || 'Fluid required',
                        (v) => nb_fluid > 0 || 'No Elements defined ',
                      ]"
                      outlined
                      :readonly="elements_list.length > 0 || test_readonly"
                      @input="changes++"
                      @change="fluid_change"
                      dense
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="2" md="2">
                    <v-autocomplete
                      :items="users_exe"
                      v-model="editedItem.agent_id"
                      item-value="id"
                      item-text="name"
                      label="Tested by"
                      outlined
                      :readonly="test_readonly"
                      @input="changes++"
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="2" md="2">
                    <datepicker
                      label="Test Date"
                      v-model="editedItem.test_date"
                      :rules="[(v) => !!v || 'Test Date required']"
                      :edit="!test_readonly"
                      :date_max="today"
                      :date_min="drillingdate"
                      @change="changes++"
                      :key="cs"
                    ></datepicker>
                  </v-col>

                  <v-col cols="12" sm="2" md="2">
                    <v-select
                      :items="users_eval"
                      v-model="editedItem.interpreted_by"
                      item-value="id"
                      item-text="name"
                      label="Evaluation by"
                      @change="changes++"
                      dense
                      :readonly="test_readonly"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="2" md="2">
                    <datepicker
                      label="Evaluation Date"
                      :edit="!test_readonly"
                      v-model="editedItem.interpreted_at"
                      :disabled="!editedItem.test_date"
                      :date_max="today"
                      :date_min="editedItem.test_date"
                      :key="cs"
                      :clearable="false"
                    ></datepicker>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      v-model="editedItem.comment"
                      label="Comment"
                      outlined
                      :readonly="test_readonly"
                      @input="changes++"
                      dense
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12" sm="12" md="12" v-if="editedItem.id > 0">
                    <v-card>
                      <v-card-title>
                        <span> Elements </span>
                        <v-spacer></v-spacer>
                      </v-card-title>
                      <v-card-actions>
                        <v-container element>
                          <v-form ref="form2" lazy-validation>
                            <v-container element>
                              <v-row v-if="!test_readonly">
                                <v-col cols="12" sm="7" md="7">
                                  <v-autocomplete
                                    :items="elements_reste"
                                    v-model="newelement.element_id"
                                    label="Component"
                                    :item-value="'id'"
                                    :item-text="'label'"
                                    :rules="[
                                      (v) => !!v || 'Component required',
                                    ]"
                                    outlined
                                    readonly
                                    dense
                                  >
                                  </v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="2" md="2">
                                  <v-text-field
                                    ref="elmval"
                                    autocomplete="off"
                                    type="number"
                                    outlined
                                    dense
                                    v-model.number="newelement.value"
                                    :label="
                                      'Value ' +
                                      (newelement.unit
                                        ? '(' + newelement.unit + ')'
                                        : '')
                                    "
                                    :rules="[
                                      (v) => v != null || 'Value required',
                                    ]"
                                    hide-spin-buttons
                                    @focus="$event.target.select()"
                                    @keydown.enter="save_element"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="1" md="1">
                                  <v-btn
                                    color="blue darken-1"
                                    :disabled="newelement.element_id == null"
                                    @click.stop="save_element"
                                  >
                                    Save
                                  </v-btn>
                                </v-col>
                                <v-col cols="12" sm="1" md="1">
                                  <v-btn
                                    color="blue darken-1"
                                    :disabled="newelement.element_id == null"
                                    @click.stop="cancel"
                                  >
                                    Cancel
                                  </v-btn>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" sm="12" md="12">
                                  <listitems
                                    :list="elements_list"
                                    :headers="elements_headers"
                                    :showstd="false"
                                    :showedit="false"
                                    :add="false"
                                    :del="false"
                                    :master="false"
                                    :exp_excel="true"
                                    :key="element_key"
                                    :ipg="-1"
                                    :hdf="true"
                                    :btn_update="!test_readonly"
                                    @btn_update_click="OpenImportForm"
                                    @rowselect="ElementSelect"
                                    @col_btn1_click="element_update"
                                    @col_btn2_click="element_delete"
                                    :laoding="data_loading"
                                    :selitem="actualelement"
                                  >
                                  </listitems>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-form>
                        </v-container>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
            <v-tab-item :value="'2'"
              ><v-container>
                <v-card>
                  <v-card-title v-if="formation_match">
                    <span>
                      {{ conclusion }}
                    </span>
                  </v-card-title>
                  <v-card-text>
                    <canvas id="myChart" width="400" height="400"></canvas>
                  </v-card-text>
                </v-card>
              </v-container>
            </v-tab-item>
            <v-tab-item :value="'3'">
              <listitems
                :list="events"
                :headers="events_header"
                :toolbar="false"
                :key="kev"
              >
              </listitems>
            </v-tab-item>
            <v-tab-item :value="'4'">
              <filelist
                :item="editedItem"
                :editer="true"
                :doc_type="10"
                :isform="false"
                :auth="'01007'"
                :well_id="parseInt(editedItem.well_id)"
              >
              </filelist
            ></v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <Test_Accept
            :test="editedItem"
            :key="ta"
            @close="close"
            @test_save="test_save"
            @status_change="status_change"
            @validate_test="validate_test"
            :save_disable="save_disable"
            :testype_id="10"
            :changes="changes"
            :test_ok="true"
          ></Test_Accept>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <confirmdialog ref="confirm" />
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
    <import_data
      :showForm="!isListClose"
      :title="title"
      :listheaders="listheaders"
      :items="elements_list"
      :init_list="elements_list"
      :item_key="'element_label'"
      :col_key="'Element'"
      :item_id="'element_id'"
      :width="width"
      :alert="alert"
      :type_alert="type_alert"
      :message="message"
      @close="isListClose = true"
      @save_import="save_import"
      :key="frml"
    ></import_data>
  </v-form>
</template>
<script>
import Chart from "chart.js";
import CREATE_FP_DETAIL from "../graphql/FingerPrint/CREATE_FP_DETAIL.gql";
import UPDATE_FP_DETAIL from "../graphql/FingerPrint/UPDATE_FP_DETAIL.gql";
import DELETE_FP_DETAIL from "../graphql/FingerPrint/DELETE_FP_DETAIL.gql";

import INSERT_FP from "../graphql/FingerPrint/INSERT_FP.gql";
import UPDATE_FP from "../graphql/FingerPrint/UPDATE_FP.gql";
import FP_UPDATE from "../graphql/FingerPrint/FP_UPDATE.gql";
import ALL_FP from "../graphql/FingerPrint/FPS_ALL_DETAIL.gql";

export default {
  components: {
    listitems: () => import("../components/ListItems.vue"),
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
    datepicker: () => import("../components/DatePicker.vue"),
    import_data: () => import("../components/ImportData.vue"),
    Test_Accept: () => import("../components/Test_Accept.vue"),
    filelist: () => import("./FileList.vue"),
  },
  props: {
    list: Array,
    showform: Boolean,
    modify: Boolean,
    item: Object,
    fluids: Array,
    fluids_elements: Array,
    wellstubulars: Array,
    user_list: Array,
    well: Object,
    editer: Boolean,
    valider: Boolean,
  },
  data() {
    return {
      test_readonly: false,
      save_disable: false,
      cs: 0,
      tab0: "1",
      document: {},
      events: [],
      events_header: [
        {
          value: "id",
          selected: false,
        },
        {
          text: "Date",
          value: "event_date",
          selected: true,
        },
        {
          text: "Operation",
          value: "statut",
          selected: true,
        },
        {
          text: "Operator",
          value: "operator",
          selected: true,
        },
        {
          text: "Comment",
          value: "comment",
          selected: true,
        },
      ],
      test_ok: false,
      isDocClose: true,
      ff: 900,
      element_key: 1000,
      elm_key: 1100,
      return_list: [],
      snackbar: false,
      snackbar_timeout: 2000,
      snackbar_text: "",
      snackbar_color: "primary",
      editedItem: {},
      changes: 0,
      depth_min: 0,
      depth_max: 0,
      element: {},
      actualelement: {},
      newelement: {},
      refrences_list: [],
      data_loading: false,
      formation_match: "",
      elements_headers: [
        {
          text: "N°",
          value: "no",
          selected: true,
          width: "10",
          sortable: false,
        },

        {
          text: "Element",
          value: "element_label",
          selected: true,
          width: "150",
          sortable: false,
          import: true,
        },
        {
          text: "Unit",
          value: "unit",
          selected: true,
          sortable: false,
          import: true,
        },
        {
          text: "Value",
          value: "value",
          selected: true,
          width: "150",
          sortable: false,
          import: true,
        },
        {
          text: "Update",
          slot: "col_btn1",
          selected: true,
          icon: "mdi-pencil",
          width: "10",
          hiden: true,
          tooltip: "Update element",
          sortable: false,
        },
      ],
      fluid_elements: [],

      btn_click: false,
      element_edit: false,
      date_min: null,
      ch2: null,
      title: "",
      isListClose: true,
      width: "1000px",
      alert: false,
      type_alert: "info",
      message: "",
      frml: 600,
      ta: 150,
      kev: 160,
      nb_fluid: 0,
      listheaders: [],
      elements_list: [],
      crossed: false,
      conclusion: "",
    };
  },
  watch: {},
  created() {
    if (this.item) {
      if (this.item.id > 0) {
        this.editedItem = Object.assign({}, this.item);
        this.elements_headers[4].hiden = this.test_readonly;
        this.cs++;
        this.elements_list = this.editedItem.details
          ? this.editedItem.details
          : [];
        this.element_key++;
        this.tubular_change();
        this.fluid_change();
      } else this.editedItem = Object.assign({}, { id: -1, statut_id: 4 });
      this.get_status();
      this.ta++;
    }
  },
  computed: {
    drillingdate() {
      return this.well ? this.well.drillingdate : this.$store.state.today;
    },
    today() {
      return this.$store.state.today;
    },
    elements_reste() {
      let l = this.fluids_elements.filter(
        (elm3) =>
          !this.elements_list
            .map((elm2) => elm2.element_id)
            .includes(elm3.id) || elm3.id == this.newelement.element_id
      );
      return l;
    },
    users_exe() {
      let l = [];
      l = this.user_list.filter((elm) => elm.autorisations.includes("06003"));
      return l;
    },
    users_eval() {
      let l = [];
      l = this.user_list.filter((elm) => elm.autorisations.includes("07003"));
      return l;
    },
  },
  methods: {
    OpenImportForm() {
      this.form_list = [];
      this.title = "Import elements data ";
      this.listheaders = this.elements_headers.filter(
        (elm) => elm.import == true
      );
      this.width = "1000px";
      this.orientation = "p";
      this.isListClose = false;
      this.frml++;
    },
    async save_import(items) {
      let ok = true;
      this.message = null;
      let list = [];
      for (let index = 0; index < items.length; index++) {
        const element = items[index];

        if (element.value != null && typeof element.value != "number" && ok) {
          this.message = "Invalid Value Number" + " - ligne " + (index + 1);
          this.type_alert = "error";
          this.alert = true;
          ok = false;
          break;
        }

        list.push({
          id: element.id,
          value: element.value,
        });
      }

      if (ok) {
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Are you sûr to update test data?",
            { color: "orange darken-3" }
          )
        ) {
          let v = {
            list: list,
          };
          let r = await this.$maj(FP_UPDATE, v);
          if (r.ok) {
            let d = JSON.parse(r.data.UpdateFingerPrint);
            this.editedItem.details = d[0].elements;
            this.elements_list = d[0].elements;
            this.snackbar_text = "Test data updated";
            this.snackbar_color = "success";
            this.snackbar = true;
            this.isListClose = true;
            this.element_key++;
            this.$emit("change", this.editedItem);
            //this.frml++;
          } else {
            this.snackbar_text = "Saving Error";
            this.snackbar_color = "error";
            this.snackbar = true;
          }
        }
      }
    },
    sortBy() {
      return function (a, b) {
        if (a < b) return 1;
        if (a > b) return -1;
        return 0;
      };
    },

    tubular_change() {
      if (this.$refs.form) this.$refs.form.resetValidation();
      if (this.editedItem.id < 0) {
        this.editedItem.fluid_id = null;
        this.editedItem.test_date = null;
      }
      let i = this.wellstubulars.findIndex(
        (elm) => elm.id == this.editedItem.well_tubular_id
      );
      if (i >= 0) this.editedItem.annulus = this.wellstubulars[i].annulus;
      this.cs++;
      this.date_min = null;
    },

    fluid_change() {
      if (this.$refs.form) this.$refs.form.resetValidation();
      if (this.editedItem.id < 0) {
        this.editedItem.test_date = null;
        this.cs++;
      }
      this.date_min = null;
      this.nb_fluid = this.fluids_elements.filter(
        (elm3) => elm3.fluid_id == this.editedItem.fluid_id
      ).length;
    },

    element_update(item) {
      this.newelement = Object.assign({}, item);
      this.element_edit = true;
      this.$refs.elmval.focus();
    },
    cancel() {
      if (!this.btn_click) {
        this.btn_click = true;

        this.$refs.form2.resetValidation();
        this.$nextTick(() => {
          this.newelement = {};
        });

        this.element_edit = false;
        this.btn_click = false;
      }
    },
    async save_element() {
      if (!this.btn_click) {
        this.btn_click = true;
        if (this.$refs.form2.validate()) {
          let ok = false;
          this.data_loading = true;
          if (this.newelement.id > -1) {
            let v = {
              fingerprintdetail: {
                id: this.newelement.id,
                element_id: this.newelement.element_id,
                value: this.newelement.value,
                write_uid: this.$store.state.me.id,
              },
            };
            let r = await this.$maj(UPDATE_FP_DETAIL, v);
            this.data_loading = false;
            if (r.ok) {
              this.elements_list.splice(
                this.newelement.index,
                1,
                this.newelement
              );
              this.newelement = {};
              this.$refs.form2.resetValidation();
              ok = true;
            } else {
              this.snackbar_text = "Saving Error";
              this.snackbar_color = "error";
              this.snackbar = true;
            }
          } else {
            let v = {
              fingerprintdetail: {
                finger_print_id: this.editedItem.id,
                element_id: this.newelement.element_id,
                value: this.newelement.value,
                create_uid: this.$store.state.me.id,
              },
            };
            let r = await this.$maj(CREATE_FP_DETAIL, v);
            if (r.ok) {
              ok = true;
            } else {
              this.snackbar_text = "Saving Error";
              this.snackbar_color = "error";
              this.snackbar = true;
            }
          }
          if (ok) {
            this.$emit("change", this.editedItem);
            this.$refs.form2.resetValidation();
            this.actualelement = this.newelement;
            this.newelement = {};
            this.element_edit = false;
            let i = this.fluids_elements.findIndex(
              (elm) => elm.id == this.newelement.element_id
            );
            if (i >= 0) {
              this.element.label = this.fluids_elements[i].label;
            }
          } else this.data_loading = false;
        }
        this.btn_click = false;
      }
    },
    ElementSelect(item) {
      this.element = item;
      this.actualelement = item;
    },
    async element_delete(item) {
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Delete fluid " + item.element_label + " !!-Are you sur ?",
          { color: "orange darken-3" }
        )
      ) {
        this.data_loading = true;
        let r = await this.$maj(DELETE_FP_DETAIL, { id: item.id });
        if (r) {
          this.$emit("change", this.editedItem);
        } else this.data_loading = false;
      }
    },
    close() {
      this.$emit("close");
    },
    get_status() {
      this.test_readonly =
        (this.editedItem.statut_id != 4 &&
          this.editedItem.statut_id != 5 &&
          this.editedItem.statut_id != 8) ||
        !this.editer;
      if (this.editedItem.statut_id == 4) this.editedItem.status = "Attributed";
      if (this.editedItem.statut_id == 5)
        this.editedItem.status = "In Progress";
      if (this.editedItem.statut_id == 6) this.editedItem.status = "Completed";
      if (this.editedItem.statut_id == 7) this.editedItem.status = "Accepted";
      if (this.editedItem.statut_id == 8) this.editedItem.status = "Rejected";
    },
    status_change(status) {
      this.editedItem.statut_id = status;
      this.get_status();
      this.$emit("update", this.editedItem);
    },
    async chart_show() {
      if (!this.ch2) {
        let formations = [];
        if (this.editedItem.statut_id == 6) {
          let r = await this.$maj(ALL_FP, {
            TypeScope: 1,
            CatScope: this.$store.state.fields,
          });
          if (r.ok) {
            this.refrences_list = r.data.fingers_prints;
            this.refrences_list
              .filter(
                (elm) =>
                  elm.statut_id == 6 &&
                  elm.field_id == this.well.field_id &&
                  elm.fluid_id == this.editedItem.fluid_id &&
                  elm.test_date <= this.editedItem.test_date
              )
              .forEach((elm) => {
                let i = formations.findIndex(
                  (elm2) => elm2.formation_id == elm.formation_id
                );
                if (i == -1) formations.push(elm);
                else {
                  if (formations[i].test_date < elm.test_date)
                    formations[i] = elm;
                }
              });
            this.elements_list.forEach((element) => {
              formations.forEach((formation) => {
                let i = formation.details.findIndex(
                  (elm) => elm.element_id == element.element_id
                );
                if (i >= 0) {
                  formation.details[i].diff = Math.abs(
                    formation.details[i].value - element.value
                  );
                }
              });
            });

            formations.forEach((formation) => {
              formation.diff = formation.details.reduce(
                (a, b) => a + (b["diff"] || 0),
                0
              );
            });
            formations.sort(this.sortBy("diff"));
            if (formations.length > 0) {
              this.formation_match = formations[0].formation_label;
              let j = this.well.wellsformations.findIndex(
                (elm) =>
                  elm.formation_id == formations[0].formation_id &&
                  elm.depthfrom > 0
              );

              this.crossed = j >= 0;
              if (formations.length > 0 && this.formation_match) {
                this.conclusion =
                  "The " +
                  this.editedItem.fluid_label +
                  " sample collected from " +
                  this.editedItem.annulus +
                  " is matching with " +
                  this.editedItem.fluid_label +
                  " reference " +
                  this.formation_match +
                  " formation" +
                  (this.crossed ? "" : " which is not crossed");
              }
            }
          }

          const data = {
            labels: [],
            datasets: [],
          };
          data.labels = this.elements_list.map((elm) => elm.element_label);
          data.datasets.push({
            label: "Data Test",
            data: this.elements_list.map((elm) => elm.value),
            fill: true,
            backgroundColor: "rgba(255, 99, 132, 0.2)",
            borderColor: "rgb(255, 99, 132)",
            pointBackgroundColor: "rgb(255, 99, 132)",
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "rgb(255, 99, 132)",
          });

          formations.forEach((element) => {
            let n1 = Math.floor(Math.random() * 255);
            let n2 = Math.floor(Math.random() * 255);
            let n3 = Math.floor(Math.random() * 255);
            data.datasets.push({
              label: element.formation_label,
              data: element.details.map((elm) => elm.value),
              fill: true,
              backgroundColor: "rgba(" + n1 + ", " + n2 + ", " + n3 + ", 0.2)",
              borderColor: "rgb(" + n1 + ", " + n2 + ", " + n3 + ")",
              pointBackgroundColor: "rgb(" + n1 + ", " + n2 + ", " + n3 + ")",
              pointBorderColor: "#fff",
              pointHoverBackgroundColor: "#fff",
              pointHoverBorderColor: "rgb(" + n1 + ", " + n2 + ", " + n3 + ")",
            });
          });

          const config = {
            type: "radar",
            data: data,
            options: {
              elements: {
                line: {
                  borderWidth: 3,
                },
              },
            },
            scales: {
              r: {
                angleLines: {
                  display: false,
                },
                suggestedMin: 50,
                suggestedMax: 100,
              },
            },
          };

          setTimeout(() => {
            var ctx = document.getElementById("myChart");
            new Chart(ctx, config);
          }, 50);
        }
      }
    },
    async validate_test() {
      if (!this.btn_click) {
        this.btn_click = true;
        if (this.$refs.form.validate()) {
          let c = "";
          let l = this.elements_list.filter((elm) => elm.value == null);
          for (let index = 0; index < l.length; index++) {
            const element = l[index];
            if (index == l.length - 1) c = c + element.element_label;
            else c = c + element.element_label + ",";
          }
          c = l.length > 0 ? "Missing Elements : " + c : "";
          if (
            await this.$refs.confirm.open(
              "Confirmation",
              "Validate this Test !!-Are you sur ?",
              { color: "orange darken-3" },
              c
            )
          ) {
            let v = {
              fingerprint: {
                id: this.editedItem.id,
                statut_id: 6,
                write_uid: this.$store.state.me.id,
              },
            };
            let r = await this.$maj(UPDATE_FP, v);
            if (r.ok) {
              this.editedItem.statut_id = 6;
              this.$emit("change", this.editedItem);
            } else {
              this.snackbar_text = "Saving Error";
              this.snackbar_color = "error";
              this.snackbar = true;
            }
          }
        }
        this.btn_click = false;
      }
    },
    async test_save() {
      if (this.$refs.form.validate()) {
        this.save_disable = true;
        //if new refrence verifie if there is fluid with null value

        if (this.editedItem.id > -1) {
          let v = {
            fingerprint: {
              id: this.editedItem.id,
              well_tubular_id: this.editedItem.well_tubular_id,
              well_id: this.well.id,
              test_type: 2,
              fluid_id: this.editedItem.fluid_id,
              test_date: this.editedItem.test_date,
              comment: this.editedItem.comment,
              write_uid: this.$store.state.me.id,
            },
          };
          let r = await this.$maj(UPDATE_FP, v);
          if (r.ok) {
            this.$emit("change", this.editedItem);
          } else {
            this.snackbar_text = "Saving Error";
            this.snackbar_color = "error";
            this.snackbar = true;
          }
        } else {
          let v = {
            list: [
              {
                well_tubular_id: this.editedItem.well_tubular_id,
                well_id: this.well.id,
                test_type: 2,
                agent_id: this.editedItem.agent_id,
                statut_id: 4,
                comment: this.editedItem.comment,
                test_date: this.editedItem.test_date,
              },
            ],
            fluid_id: this.editedItem.fluid_id,
          };
          let r = await this.$maj(INSERT_FP, v);
          if (r.ok) {
            let d = JSON.parse(r.data.InsertFingerPrint);
            this.editedItem.id = d[0].id;
            this.editedItem.code = d[0].code;
            this.editedItem.details = d[0].elements;
            this.elements_list = d[0].elements;
            this.element_key++;
            this.$emit("add", this.editedItem);
          } else {
            this.snackbar_text = "Saving Error";
            this.snackbar_color = "error";
            this.snackbar = true;
          }
        }
        this.changes = 1;
        this.save_disable = false;
      }
    },
    numberWithSpace: function (x) {
      return x
        ? x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ")
        : "0";
    },
  },
};
</script>
